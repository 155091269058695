import {createApp} from 'vue'
import App from './App.vue'

import router from './router';
import store from './store';


// PrimeVue related imports
import Tooltip from 'primevue/tooltip';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice'; // Import the DialogService
import DynamicDialog from "primevue/dynamicdialog"; // Adjust the path as necessary
import ConfirmationService from 'primevue/confirmationservice';
// Import and globally register components
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Card from 'primevue/card';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';

// Import Main styling scss file
import '@/assets/scss/styles.scss'; //
// Import Firebase configuration to initialize it
import './config/firebase.config';

const app = createApp(App);

// Register PrimeVue and services
app.use(PrimeVue, {
    theme: {
        preset: Aura
    },
    unstyled: false
});

app.directive('tooltip', Tooltip);
app.component('DynamicDialog', DynamicDialog);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Button', Button);
app.component('InputText', InputText);
app.component('Dropdown', Dropdown);
app.component('IconField', IconField);
app.component('InputIcon', InputIcon);
app.component('Card', Card);
app.component('Dialog', Dialog);
app.component('Calendar', Calendar);

app.use(ToastService);
app.use(DialogService);  // Register the DialogService globally
app.use(ConfirmationService);  // Register the DialogService globally
app.use(store);
app.use(router);

app.mount('#app')
