// Create an Axios instance
import axios, {AxiosInstance, InternalAxiosRequestConfig} from "axios";
import {getAuth} from "firebase/auth";


const apiClient: AxiosInstance = axios.create({
    baseURL: 'https://lendesca-2024-cloud-functions.uc.r.appspot.com', // Replace with your API base URL
    // baseURL: 'http://localhost:8080', // Replace with your API base URL
    headers: {
        'Content-Type': 'application/json',
    },
});

// Interceptor to add the Firebase Bearer token to every request
apiClient.interceptors.request.use(
    async (config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig<any>> => {
        try {
            const auth = getAuth();
            const user = auth.currentUser;

            if (user) {
                const token = await user.getIdToken(); // Get the Firebase token
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`; // Add token to request header
                }
            }

            // Always return the config, even if there's no user or token
            return config;
        } catch (error) {
            console.error('Error adding Firebase token to request', error);
            return config; // Return the config even if there was an error
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default apiClient;
